<template>
  <div>
      <a-modal  @ok="ok" @cancel="cancel" :visible="popup" :title="title">
          <div class="select-image-grid">
              <div v-for="item in options" :key="item.value" :class="item.value === selected ? 'selected-option' : ''">
                  <picture @click="selected = item.value" class="image-select-picture pic-holder" :style="{backgroundImage:`url(${item.img})`}"><span v-text="item.label" /></picture>
              </div>
          </div>
      </a-modal>
      <picture class="option-selected pic-holder" :style="styles" @click="popup = true"/>

  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:'Please select one...'
        },
        value:{
            type:[String,Number],
            default:'',
        },
        options:{
            type:Array,
            default:() => []
        }
    },
    data:() => ({
        selected:'',
        popup:false,
    }),
    computed:{
        styles(){
            if (this.options && this.options.length){
                let found = this.options.find(x => x.value === this.value)
                if (found && found.img){
                    return {
                        backgroundImage:`url(${found.img})`
                    }
                }
            }
            return {}
        }
    },
    methods:{
        ok(){
            if (this.selected !== this.value){
                this.$emit('input',this.selected)
            }
            this.popup = false
        },
        cancel(){
            this.popup = false
        }
    },
    created(){
        if (typeof this.value === 'number' || typeof this.value === 'string'){
            this.selected = this.value
        }
    }
}
</script>

<style scoped lang=scss>
    
    .pic-holder{
        cursor:pointer;
        background-color:#eee;
        background-repeat: no-repeat;
        display:block;
        background-position: center;

    }
    .select-image-grid{
        display:grid;
        grid-template-columns: repeat(2, minmax(0,1fr));   
        gap:20px;
        
        .image-select-picture{
            height:0;
            border:2px solid transparent;
            background-size:contain;
            padding-bottom:66%;
            position:relative;
            span{
                font-size:12px;
                opacity:0;
                position:absolute;
                display:block;
                left:15px;
                top:100%;
                width:calc(100% - 30px);
                transform:translate3d(0,-30%,0);
                text-align: center;
                pointer-events:none;
                transition: opacity .3s ease-out, transform .3s ease-out;
                background-color:rgba(255,255,255,1);
                padding:3px;
                box-shadow: .5px 1px 3px rgba(100,100,100,0.3);
            }
            &:hover{
                span{
                    opacity:1;
                    transform:translate3d(0,-100%,0);
                }
            }
        }
        >.selected-option{
            picture{
                border-color:var(--primary);
            }
            span{
                background:var(--primary);
                color:white;
                opacity:1;
                transform:translate3d(0,-100%,0);
            }
        }
        
        
    }
    picture.option-selected{
        width:100px;
        height:100px;
        background-size:contain;
        border:1px dashed #ccc;
        border-radius:5px;
        &:hover{
            border-color:var(--primary);
        }
    }
</style>